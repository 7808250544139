<template>
  <NavBar/>
  <HeroBlock/>
  <WhyBlock/>
  <PositiveBlock/>
  <CompanyBlock/>
  <PriceBlock/>
  <FaqBlock/>
  <FooterBlock/>
</template>

<script>
import { inject } from "@vercel/analytics"
import {onMounted} from "vue";
import HeroBlock from "@/components/HeroBlock.vue";
import WhyBlock from "@/components/WhyBlock.vue";
import PositiveBlock from "@/components/PositiveBlock.vue";
import PriceBlock from "@/components/PriceBlock.vue";
import FaqBlock from "@/components/FaqBlock.vue";
import FooterBlock from "@/components/FooterBlock.vue";
import CompanyBlock from "@/components/CompanyBlock.vue";
import NavBar from "@/components/NavBar.vue";

export default {
  name: 'App',
  components: {NavBar, CompanyBlock, FooterBlock, FaqBlock, PriceBlock, PositiveBlock, WhyBlock, HeroBlock},
  title: 'Warehouse Management Simple',
  setup() {
    onMounted(() => {
      inject();
    })
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
