<script setup>

</script>

<template>
  <div class="text-5xl text-900 mb-1 text-center" style="font-weight: 900">Dal problema alla soluzione</div>
  <div class="px-4 md:px-6 lg:px-8 flex align-items-center justify-content-center">
    <div class="p-5 shadow-2 border-round mt-4 mb-8 py-7 w-full lg:w-8 bg-primary">
      <div class="font-medium text-2xl mb-3 text-left">
        <p>Gestisci il tuo magazzino in tempo reale, senza intoppi.</p>
        <p>Basta aspettare per avere i tuoi dati utilizzabili.</p>
        <p>Personalizzata in base alle tue esigenze.</p>
        <p>Ottieni di più, a parità di prezzo.</p>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
