<script setup>

</script>

<template>
  <div class="surface-section px-4 py-8 md:px-6 lg:px-8 mt-8 mb-8">
    <div class="text-700 text-center">
      <div class="text-black-600 text-2xl font-bold mb-3"><i class="pi pi-warehouse text-2xl"></i>&nbsp;WMS Standalone</div>
      <div class="text-900 text-7xl mb-3" style="font-weight: 900">Gestisci il tuo magazzino<br>con un click</div>
      <div class="text-700 text-xl mb-5">Il primo vero Saas utilizzabile da smartphone e senza costi nascosti.</div>
      <a href="#pricing" style="text-decoration: none">
        <PrimeButton label="Inizia da Oggi" class="font-bold px-5 py-3 p-button-raised p-button-rounded border-round-xl white-space-nowrap"></PrimeButton>
      </a>
    </div>
  </div>
</template>

<style scoped>

</style>
