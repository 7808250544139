<script setup>

</script>

<template>
  <div class="px-4 md:px-6 lg:px-8 flex align-items-center justify-content-center">
    <div class="surface-card p-5 shadow-2 border-round mt-8 mb-8 py-7 w-full lg:w-8">
      <div class="text-5xl text-900 mb-3 text-left" style="font-weight: 900">Basta sprechi, semplifica la tua gestione.</div>
      <div class="font-medium text-black-600 text-2xl mb-3 text-left">
        <p>Quando tempo si perde a controllare la merce o a fare l'inventario.</p>
        <p class="font-italic">Cosa accadrebbe cambiando metodo di lavoro?</p>
        <p class="font-bold">Sprigiona il massimo valore della tua azienda.</p>
        <p>Risparmia tempo per altre attività.</p>
        <a href="#pricing" style="text-decoration: none">
          <PrimeButton label="Inizia da Oggi" class="font-bold px-5 py-3 p-button-raised p-button-rounded border-round-xl white-space-nowrap"></PrimeButton>
        </a>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
