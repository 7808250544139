<script setup>

</script>

<template>
  <div class="sticky top-0 z-5">
    <PrimeMenubar>
      <template #start>
        <a href="#" style="color: inherit; text-decoration:none">
          <div class="text-black-600 text-xl font-bold"><i class="pi pi-warehouse text-2xl"></i>&nbsp;WMS Standalone</div>
        </a>
      </template>
      <template #end>
        <a href="#pricing" style="text-decoration: none">
          <PrimeButton label="Inizia da Oggi" class="font-bold px-5 py-3 p-button-raised p-button-rounded border-round-xl white-space-nowrap"></PrimeButton>
        </a>
      </template>
    </PrimeMenubar>
  </div>
</template>

<style scoped>

</style>
