<script setup>

</script>

<template>

  <div class="surface-ground px-4 py-8 md:px-6 lg:px-8" id="pricing">
    <div class="text-900 font-bold text-6xl mb-4 text-center">Prezzi</div>
    <div class="text-700 text-xl mb-4 text-center line-height-3">Scegli quello che fa al caso tuo.</div>

    <div class="grid" style="justify-content: center">

      <div class="col-12 lg:col-4">
        <div class="p-3 h-full">
          <div class="shadow-2 p-3 h-full flex flex-column surface-card" style="border-radius: 6px">
            <div class="text-900 font-medium text-xl mb-2">Premium</div>
            <div class="text-600">L'importante è iniziare</div>
            <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
            <div class="flex align-items-center">
              <span class="font-bold text-2xl text-900">Free</span>
              <span class="ml-2 font-medium text-600"></span>
            </div>
            <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
            <ul class="list-none p-0 m-0 flex-grow-1">
              <li class="flex align-items-center mb-3 text-left">
                <i class="pi pi-check-circle text-green-500 mr-2"></i>
                <span>Accesso anticipato</span>
              </li>
              <li class="flex align-items-center mb-3 text-left">
                <i class="pi pi-check-circle text-green-500 mr-2"></i>
                <span>Utenti illimitati</span>
              </li>
              <li class="flex align-items-center mb-3 text-left">
                <i class="pi pi-check-circle text-green-500 mr-2"></i>
                <span>3 Export personalizzati</span>
              </li>
            </ul>
            <hr class="mb-3 mx-0 border-top-1 border-none surface-border" />
            <a href="mailto:wms@unicorne.it?subject=Prenota%20il%20WMS" style="text-decoration: none">
             <PrimeButton label="Riserva uno spazio" class="p-3 w-full p-button-outlined"></PrimeButton>
            </a>
          </div>
        </div>
      </div>

      <div class="col-12 lg:col-4">
        <div class="p-3 h-full">
          <div class="shadow-2 p-3 flex flex-column surface-card" style="border-radius: 6px">
            <div class="text-900 font-medium text-xl mb-2">Enterprise</div>
            <div class="text-600">Il massimo, subito!</div>
            <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
            <div class="flex align-items-center">
              <span class="font-bold text-2xl text-900">€ 200,00</span>
              <span class="ml-2 font-medium text-600"></span>
            </div>
            <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
            <ul class="list-none p-0 m-0 flex-grow-1">
              <li class="flex align-items-center mb-3 text-left">
                <i class="pi pi-check-circle text-green-500 mr-2"></i>
                <span>Accesso anticipato</span>
              </li>
              <li class="flex align-items-center mb-3 text-left">
                <i class="pi pi-check-circle text-green-500 mr-2"></i>
                <span>Utenti illimitati</span>
              </li>
              <li class="flex align-items-center mb-3 text-left">
                <i class="pi pi-check-circle text-green-500 mr-2"></i>
                <span>3 Export personalizzati</span>
              </li>
              <li class="flex align-items-center mb-3 text-left">
                <i class="pi pi-check-circle text-green-500 mr-2"></i>
                <span>Assistenza prioritaria sul primo anno</span>
              </li>
              <li class="flex align-items-center mb-3 text-left">
                <i class="pi pi-check-circle text-green-500 mr-2"></i>
                <span>Sconto sul primo anno</span>
              </li>
            </ul>
            <hr class="mb-3 mx-0 border-top-1 border-none surface-border" />
            <a href="https://book.stripe.com/00g9Be6e4dq8g4E7ss" style="text-decoration: none">
              <PrimeButton label="Preordina Ora" class="p-3 w-full"></PrimeButton>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
