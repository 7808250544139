<script setup>

</script>

<template>

  <div class="surface-section px-4 py-8 md:px-6 lg:px-8 text-center">
    <div class="grid">
      <div class="col-12 md:col-4 mb-4 px-5">
            <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                <i class="pi pi-desktop text-4xl text-primary-500"></i>
            </span>
        <div class="text-900 text-xl mb-3 font-medium">Qualsiasi Dispositivo</div>
        <span class="text-700 line-height-3">Stesse funzionalità anche con dispositivi diversi, non importa se smartphone, tablet o pc.</span>
      </div>
      <div class="col-12 md:col-4 mb-4 px-5">
            <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                <i class="pi pi-lock text-4xl text-primary-500"></i>
            </span>
        <div class="text-900 text-xl mb-3 font-medium">Sicurezza al Centro</div>
        <span class="text-700 line-height-3">I tuoi dati sono protetti, la nostra infrastruttura è totalmente cloud e costantemente aggiornata.</span>
      </div>
      <div class="col-12 md:col-4 mb-4 px-5">
            <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                <i class="pi pi-check-circle text-4xl text-primary-500"></i>
            </span>
        <div class="text-900 text-xl mb-3 font-medium">Facile da Usare</div>
        <span class="text-700 line-height-3">Niente bottoni ovunque o schermate complicate, ogni passo sarà intuitivo e facilitato.</span>
      </div>
      <div class="col-12 md:col-4 mb-4 px-5">
            <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                <i class="pi pi-globe text-4xl text-primary-500"></i>
            </span>
        <div class="text-900 text-xl mb-3 font-medium">Supporto Veloce & Globale</div>
        <span class="text-700 line-height-3">Assistenza completa 24/7 con la possibilità di risposta immediata per ogni necessità.</span>
      </div>
      <div class="col-12 md:col-4 mb-4 px-5">
            <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                <i class="pi pi-microchip-ai text-4xl text-primary-500"></i>
            </span>
        <div class="text-900 text-xl mb-3 font-medium">Sistema Integrabile</div>
        <span class="text-700 line-height-3">Hai già un sistema per il monitoraggio o vuoi esportare i dati? Sì, abbiamo le API.</span>
      </div>
      <div class="col-12 md:col-4 md:mb-4 mb-0 px-3">
            <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                <i class="pi pi-shield text-4xl text-primary-500"></i>
            </span>
        <div class="text-900 text-xl mb-3 font-medium">Trasparenza</div>
        <span class="text-700 line-height-3">Non ci sono costi nascosti o clausole segrete. Puoi fermare l'abbonamento quando vuoi.</span>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
