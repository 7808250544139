import { createApp } from 'vue'
import PrimeVue from 'primevue/config';
import App from './App.vue'
import mixinTitle from './mixins/title';
import Button from "primevue/button"
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Menubar from 'primevue/menubar';

import 'primevue/resources/themes/aura-light-green/theme.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
import Avatar from "primevue/avatar";


const app = createApp(App);
app.mixin(mixinTitle);
app.use(PrimeVue);
app.component('PrimeButton', Button);
app.component('PrimeAccordion', Accordion);
app.component('PrimeAccordionTab', AccordionTab);
app.component('PrimeAvatar', Avatar);
app.component('PrimeMenubar', Menubar);
app.mount('#app');
