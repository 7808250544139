<script setup>

</script>

<template>

  <div class="surface-section px-4 py-5 md:px-6 lg:px-8 border-top-1 mx-8">
    <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
      <li>
        <a class="text-500 no-underline line-height-3"><i class="pi pi-globe mr-2"></i>Sviluppato da <a class="text-primary font-bold" style="text-decoration: none" href="https://unicorne.it" target="_blank">Unicorne</a></a>
      </li>
    </ul>
    <div class="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
      <div>
        <div class="text-black-600 text-2xl font-bold text-center md:text-left"><i class="pi pi-warehouse text-2xl"></i>&nbsp;WMS Standalone</div>
        <div class="flex align-items-center text-700 flex-wrap text-sm">
          <div class="md:mr-5 flex align-items-center mt-3">
            <span>© Copyright {{ new Date().getFullYear() }} WMS Standalone. Tutti i diritti riservati.</span>
          </div>
        </div>
      </div>
      <div class="mt-3 lg:mt-0">
        <a href="#pricing" style="text-decoration: none">
          <PrimeButton label="Inizia Subito" class="p-button md:mr-2" icon="pi pi-user-plus"></PrimeButton>
        </a>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
